<template>
  <div>
    <!--  background: var(--v-bg-base); -->
      <div style="min-height: calc(100vh - 350px)" >
           <div color="secondary" v-show="listData.length===0">
           <h4 style="background: var(--v-item_card-base);" class="pa-8"> No data available</h4>
          </div>
            <v-container
            v-if="listData.length"
            fluid
          >
            <v-row>
            <v-col  cols="12" sm="6" xs="12" v-for="(item, index) in listData"
              :key="index">
              <div class=" menu pa-4 ">
                <div class="icon_style">
                <slot name="icon"></slot>
              </div>
              <div>
                <slot name="item" :item="item"></slot>
              </div>
              <!-- 下方小图标 -->
              <div style="position: absolute; bottom: 10px; width: 100%">
                <slot name="active" :item="item"> </slot>
              </div>
              </div>
            </v-col>
            </v-row>
            </v-container>
      </div>
    <!-- 页码 -->
    <div style="height: 60px; width: 100%">
      <v-pagination
              class="float-right"
              v-model="page"
              color="accent"
              :length="length"
              :total-visible="6"
              @input="$emit('change', {page:page})"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
    listCount: {
      type: Number,
      default: [],
    },
  },
  data() {
    return {
      listData: [],
      dialog: true,
      length: 1,
      page: 1,
    };
  },
  methods: {},
  watch: {
    list(newVal, oldVal) {
        this.listData = newVal;
        this.length = this.listCount;
    },
  },
  mounted() {
    this.listData = this.list;
    this.length = this.listCount;
  },
};
</script>
<style  lang="stylus" scoped>
.menu {
  height: 200px;
  background: var(--v-item_card-base);
  position: relative;
  color: #b5bac1;
  user-select: none;
  cursor: pointer;
  transition: ease 0.1s all;

  &__icon {
    font-size: 28px;
  }

  &:hover {
    background: rgba(91, 163, 235, 0.12);
    color: #5BA3EB;
    transition: ease 0.1s all;
  }
}

.icon_style {
  right: 20px;
  top: 10px;
  position: absolute;
  width: 120px;
  color: var(--v-primary-base);
  opacity: 0.1;
}
@media (min-width: 1264px) and (max-width: 1903px) {
    .col-12 {
        width: 20%;
        max-width: 20%;
        flex-basis: 20%;
    }
}
@media (min-width: 1904px)  {
    .col-12 {
        width: 20%;
        max-width: 20%;
        flex-basis: 20%;
    }
}
</style>
