<template>
    <div :is="layout">
        <v-container fluid>
            <table-list :list="desserts" :listCount="list_count" @change="changePage">
                <template #icon>
                    <i class="airport icon-yonghuliebiao icon_font"></i>
                </template>
                <template #item="{item}">
                    <div style="margin-bottom:10px">{{ item.username }}</div>
                    <div class="small_font">{{ $t("auth.email") }}: {{ item.userinfo && item.userinfo.email ||
                        $t('auth.not_yet') }}
                    </div>
                    <div class="small_font" v-if="item.groups[0]">{{ $t("auth.role") }}: {{
                        groupText(item.groups[0])}}
                    </div>
                    <div class="small_font" v-if="item.groups[0]">{{ $t("auth.status") }}: {{ item.is_active ?
                        $t("auth.normal") : $t("auth.disable") }}
                    </div>
                    <div class="small_font" v-if="item.groups[0]">{{ $t("auth.administrator") }}: {{ item.is_superuser ?
                        $t("auth.yes") : $t("auth.no") }}
                    </div>
                </template>
                <template #active="{item}">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   color="secondary"
                                   outlined
                                   text
                                   @click="edit_open(item)"
                                   v-on="on" x-small fab>
                                <i class="airport small-font icon-bianji "
                                   style='font-size: 18px !important; color: #b5bac1'></i>
                            </v-btn>
                        </template>
                        <span>{{ $t('edit') }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   color="secondary"
                                   text
                                   v-on="on"
                                   outlined
                                   @click="open_edit_user_passowrd(item)"
                                   class="ml-2" depressed x-small fab>
                                <i class="airport small-font icon-mima"
                                   style='font-size: 18px !important; color: #b5bac1'></i>
                            </v-btn>
                        </template>
                        <span>{{ $t('auth.edit_user_password') }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   color="secondary"
                                   text
                                   v-on="on"
                                   outlined
                                   @click="open_role(item)"
                                   class="ml-2" depressed x-small fab>
                                <v-icon class="airport small-font" style='font-size: 18px !important; color: #b5bac1'>
                                    mdi-account-edit-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('auth.modify_role') }}</span>
                    </v-tooltip>
                </template>
            </table-list>
        </v-container>
        <v-btn @click="add_open" color="primary" fab class="float-btn">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-dialog v-model="dialog" width="800px">
            <v-card color="secondary">
                <v-card-title>{{ $t(edit_mode ? 'auth.edit_user' : 'auth.add_user') }}</v-card-title>
                <v-container class="pd-30">
                    <v-row>
                        <v-col cols="8">
                            <v-text-field v-model="info.username" :label="$t('auth.username')"></v-text-field>
                        </v-col>
                      <v-col cols="8" v-show="!edit_mode">
                        <v-select v-model="info.role" :items="groupType" :label="$t('auth.role')"></v-select>
                      </v-col>
                        <v-col cols="8" v-show="!edit_mode">
                            <v-text-field v-model="info.password" :label="$t('auth.password')"></v-text-field>
                        </v-col>
                        <v-col cols="8" v-show="!edit_mode">
                            <v-text-field v-model="info.re_password" :label="$t('auth.re_password')"></v-text-field>
                        </v-col>
                      <v-col cols="8">
                        <v-text-field v-model="info.phone" :label="$t('auth.phone')"></v-text-field>
                      </v-col>
                        <v-col cols="8">
                            <v-text-field v-model="info.email" :label="$t('auth.email')"></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field v-model="info.address" :label="$t('auth.address')"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="submit" color="accent">{{ $t('confirm') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog width="800px" v-model="edit_password_dialog">
            <v-card color="secondary">
                <v-card-title>{{ $t('auth.edit_user_password') }}</v-card-title>
                <v-container style="padding: 30px">
                    <v-row>
                        <v-col>
              <span
                      class="text--primary">{{ $t('auth.changing') }}[{{ edit_user_password_info.username }}]{{ $t('auth.extra_words') }}</span>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="password" onautocomplete="false" :label="$t('auth.user_new_password')"
                                          v-model="edit_user_password_info.new_password"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="password" onautocomplete="false"
                                          :label="$t('auth.re_user_new_password')"
                                          v-model="edit_user_password_info.re_new_password"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="submit_password" color="accent">{{ $t("confirm") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog width="800px" v-model="role_dialog">
            <v-card>
                <v-card-title>
                    {{ $t('auth.modify_role') }}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col :cols="12">
                            <v-select :label="$t('auth.role')" return-object :items=groupType v-model="info.groups"
                                      @change="changRole"></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="editRole" color="accent">{{ $t('confirm') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
    import {user_list, add_user, edit_user, edit_user_password, edit_role} from '../../../api/auth'
    import {get_group} from "../../../api/permissions";
    import TableList from '@/components/TableList'

    export default {
        components: {TableList},
        data() {
            return {
                dialog: false,
                loading: false,
                list_count: 0,
                edit_mode: false,
                add_loading: false,
                desserts: [],
                layout: null,
                edit_user_password_info: {
                    id: '',
                    username: '',
                    new_password: '',
                    re_new_password: '',
                },
                search_info: {
                    pageindex: 1,
                    pagesize: 10,
                    username: ""
                },
                items: [
                    {text: "是", value: 0},
                    {text: "否", value: 1}
                ],
                edit_password_dialog: false,
                info: {
                    // id: "",
                    username: "",
                    re_password: '',
                    address: '',
                    phone: '',
                    role: '',
                    password: "",
                    email: "",
                },
                role_dialog: false,
                groupType: []
            }
        },
        methods: {
            submit_password() {
                if (this.edit_user_password_info.new_password.length < 6) {
                    return this.$toast({
                        title: this.$t('error'),
                        message: this.$t("auth.psd_length"),
                        type: 'warning'
                    })
                }
                if (this.edit_user_password_info.new_password !== this.edit_user_password_info.re_new_password) {
                    return this.$toast({
                        title: this.$t('error'),
                        message: this.$t("auth.inconsistent"),
                        type: 'warning'
                    })
                }
                edit_user_password({
                    id: this.edit_user_password_info.id,
                    new_password: this.edit_user_password_info.new_password
                }).then(res => {
                    this.$toast({
                        title: this.$t('success'),
                        message: this.$t('auth.edit_success_psd')
                    })
                }).finally(() => {
                    this.edit_password_dialog = false
                })
            },
            open_edit_user_passowrd(data) {

                this.edit_user_password_info = {
                    id: '',
                    username: '',
                    new_password: '',
                    re_new_password: '',
                }
                this.edit_user_password_info.username = data.username
                this.edit_user_password_info.id = data.id
                this.edit_password_dialog = true

            },
            edit_open(data) {
                this.edit_mode = true
                this.info = {
                    id: data.id,
                    username: data.username,
                    email: data?.userinfo?.email || '',
                    address: data?.userinfo?.address || '',
                    phone: data?.userinfo?.phone || '',
                }
                this.dialog = true
            },
            changePage(e) {
                // this.search_info.pagesize = e.itemsPerPage
                this.search_info.pageindex = e.page
                this.doGet()
            },
            add_open() {
                this.edit_mode = false
                this.info = {
                    username: "",
                    password: "",
                    address: '',
                    phone: '',
                    role: '',
                    re_password: '',
                    email: "",
                }
                this.dialog = true
            },
            edit(data) {
                this.edit_mode = true
                for (let i in this.info) {
                    this.info[i] = data[i]
                }
                this.info.id = data.id
                this.dialog = true
            },
            doGet(pageindex = this.search_info.pageindex) {
                this.search_info.pageindex = pageindex
                this.loading = true
                const closeLoading = this.$loading()
                user_list(this.search_info).then(res => {
                    this.desserts = res.results
                    this.list_count = Math.ceil(res.count / this.search_info.pagesize)
                }).finally(() => {
                    this.loading = false
                    closeLoading()
                })
            },
            submit() {

                this.add_loading = true


                if (!/^\w+@\w+\.\w{2,5}/.test(this.info.email)) {
                    return this.$toast({
                        title: this.$t('error'),
                        message: this.$t("auth.email_error"),
                        type: 'warning'
                    })
                }

                if (this.edit_mode) {
                    edit_user(this.info).then(res => {
                        this.$toast({
                            title: this.$t('success'),
                            message: this.$t("auth.success_user_info")
                        })
                        this.dialog = false
                    }).finally(() => {
                        this.add_loading = false
                        this.doGet()
                    })
                } else {
                    this.info.password = this.info.password.trim()
                    this.info.re_password = this.info.re_password.trim()
                    if (this.info.password.length < 6) {
                        return this.$toast({
                            title: this.$t('error'),
                            message: this.$t("auth.psd_length_less"),
                            type: 'warning'
                        })
                    }

                    if (this.info.re_password !== this.info.password) {
                        return this.$toast({
                            title: this.$t('error'),
                            message: this.$t("auth.inconsistent"),
                            type: 'warning'
                        })
                    }


                    add_user(this.info).then(res => {
                        this.$toast({
                            title: this.$t('success'),
                            message: this.$t("auth.add_user_info")
                        })
                        this.dialog = false
                    }).finally(() => {
                        this.add_loading = false
                        this.doGet()
                    })
                }
            },
            async getGroups() {
                const {results} = await get_group()
                this.groupType = results.map(item => ({
                    text: item.name,
                    value: item.id
                }))
            },
            open_role(item) {
                this.info = JSON.parse(JSON.stringify(item))
                if (this.info.groups.length) {
                    this.info.groups = this.info.groups[0]
                } else
                    this.info.groups = ''
                this.role_dialog = true
            },
            async editRole() {
                const {id, groups} = this.info
                if (groups) {
                    try {
                        const {msg} = await edit_role({
                            user_id: id,
                            group: groups
                        })
                        this.$toast({
                            title: this.$t('success'),
                            message: msg
                        })
                        this.doGet()
                    } catch (e) {
                        this.$toast({
                            title: this.$t('fail'),
                            message: e
                        })
                    }
                    this.role_dialog = false
                } else
                    this.$toast({
                        title: this.$t('error'),
                        message: this.$t("auth.empty_group"),
                        type: 'warning'
                    })
            },
            changRole() {
                this.info.groups = this.info.groups.value
            }
        },
        computed: {
            headers() {
                return [
                    {
                        text: this.$t("auth.username"),
                        align: "center",
                        sortable: false,
                        value: "username",
                    },
                    {
                        text: this.$t("auth.email"),
                        value: "email",
                        sortable: false,
                        align: "center",
                    },
                    {
                        text: this.$t("aircraft.Action"),
                        value: "actions",
                        sortable: false,
                        align: "center",
                    },
                ];
            },
            groupText() {
                return function (id) {
                    if (id) {
                        const {text} = this.groupType.find(item => item.value === id)
                        return text
                    }
                }
            },
        },
        mounted() {
            this.layout = this.$route.meta.use_layout
            this.getGroups()
            this.doGet()
        }
    }
</script>
<style lang="stylus" scoped>
    @import '~@/assets/css/global.styl'
    .small_font
        font-size 14px !important
        padding 2px 0px

    .icon_font
        font-size 120px !important
</style>
